import { getReviewsForMember } from '@api/reviews';
import storefrontsQuery from '@api/storefronts';
import { ReviewsResponseData } from '@typings/api/reviews';
import { ReviewWithDetails } from '@typings/reviews';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { loadable } from 'jotai/utils';
import { useEffect } from 'react';

interface ViewMemberReviews {
	reviewsWithDetails: ReviewWithDetails[];
	totalCount: number;
}

const memberIdAtom = atom<string | undefined>(undefined);
const reviewsWithDetailsLoadableAtom = loadable(
	atom<Promise<ViewMemberReviews>>(async (get) => {
		const validReviews: ReviewWithDetails[] = [];
		const memberId = get(memberIdAtom);
		if (!memberId) return { reviewsWithDetails: [], totalCount: 0 };
		const response: ReviewsResponseData = await getReviewsForMember(
			memberId,
		).then((res) => res.json());

		const { data, totalCount } = response;
		const activeReviews = data.filter((review) => review.active);
		if (activeReviews.length) {
			const ids = activeReviews.map((review) => review.profileGuid);
			const storefrontsResponse = await storefrontsQuery({ ids });
			const vendors: Vendor.Raw[] =
				storefrontsResponse.data.data.storefronts?.profiles;

			activeReviews.forEach((review) => {
				const profile = vendors.filter((v) => v.id === review.profileGuid)[0];
				if (profile) {
					const profileWithAddress = {
						...profile,
						address: profile.location.address,
					};
					validReviews.push({ ...review, ...{ profile: profileWithAddress } });
				}
			});
		}
		return { reviewsWithDetails: validReviews, totalCount };
	}),
);

const useViewMemberReviews = (m: string) => {
	const setMemberId = useSetAtom(memberIdAtom);
	const value = useAtomValue(reviewsWithDetailsLoadableAtom);
	useEffect(() => {
		setMemberId(m);
	}, [m]);
	return value;
};

export { useViewMemberReviews };
