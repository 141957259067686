import Filmstrip from '@components/reviews/components/filmstrip';
import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { ReviewWithDetails } from '@typings/reviews';
import MoreOrLess, {
	OnToggleParams,
} from '@xo-union/tk-component-more-or-less';
import { Stars } from '@xo-union/tk-component-vendor-card';
import React from 'react';
import { Link } from 'react-router-dom';
import AnalyticsConstants from '../../../../../../../constants/analytics';
import VendorAnalytics from '../../../../../../decorators/vendorAnalytics';
import ACTIONS from '../../../../constants';
import Styles from './styles.scss';

interface Props {
	review: ReviewWithDetails;
	vendor: Vendor.Raw;
}

const Review = (props: Props) => {
	const { review, vendor } = props;
	const { rating, content, id, gallery, profile } = review;
	const vendorUrl = vendor.siteUrls[0].relativeUri.replace('/marketplace', '');
	const modalUrl = `/review-wedding-vendors${vendorUrl}/${id}#review-modal`;
	const { track } = useAnalyticsContext();
	const trackReviewEdit = () => {
		const vendorAnalytics = new VendorAnalytics(vendor);
		track({
			event: AnalyticsConstants.VENDOR_REVIEW_INITIATED,
			properties: {
				sourcePage: 'Review Hub',
				...vendorAnalytics.baseEventProps(),
			},
		});
	};

	const trackReviewToggle = (info: OnToggleParams) => {
		const selection = info.isTruncated
			? AnalyticsConstants.READ_LESS
			: AnalyticsConstants.READ_MORE;
		track({
			event: AnalyticsConstants.REVIEW_INTERACTION,
			properties: {
				sourcePage: AnalyticsConstants.REVIEW_HUB,
				selection,
			},
		});
	};

	return (
		<div className={Styles.reviewWrapper}>
			<div className={Styles.headingWrapper}>
				<div
					itemProp="reviewRating"
					itemScope
					itemType="http://schema.org/Rating"
				>
					<meta itemProp="ratingValue" content={rating.toFixed(1)} />
					<span className={Styles.reviewStar}>
						<Stars count={rating} />
					</span>
				</div>
			</div>
			<MoreOrLess
				className={Styles.bodyWrapper}
				content={content}
				charLimit={ACTIONS.MORE_OR_LESS_CHAR_LIMIT}
				onToggle={trackReviewToggle}
			/>
			<Filmstrip gallery={gallery} profile={profile} />
			<Link
				onClick={trackReviewEdit}
				className={Styles.link}
				to={{
					pathname: `${modalUrl}`,
					state: {
						review,
						vendor,
					},
				}}
			>
				Edit Review
			</Link>
		</div>
	);
};

export default Review;
