import { useViewMemberReviews } from '@components/reviews/hooks/use-view-member-reviews';
import { Snackbar, SnackbarRef } from '@xo-union/tk-component-alerts';
import Spinner from '@xo-union/tk-component-spinner';
import { H4 } from '@xo-union/tk-ui-typography';
import React, { useEffect, useRef } from 'react';
import EmptyState from './components/EmptyState';
import ReviewWrapper from './components/ReviewWrapper';
import { useSetVendorForFilmstrip } from './hooks/use-set-vendor-for-filmstrip';
import Styles from './styles.scss';

interface Props {
	member: Membership.Member;
}

const ReviewsWrapper = (props: Props) => {
	const { member } = props;
	const snackbarRef = useRef<SnackbarRef>(null);
	const memberReviews = useViewMemberReviews(member.id);
	const reviews =
		memberReviews.state === 'hasData'
			? memberReviews.data.reviewsWithDetails
			: [];
	useSetVendorForFilmstrip();

	useEffect(() => {
		if (memberReviews.state === 'hasError') {
			handleErrors();
		}
	}, [memberReviews.state]);

	const handleErrors = () => {
		if (snackbarRef.current) {
			return snackbarRef.current.showAndDismiss({
				data: 'There were some issues while fetching your reviews. Please try again later!',
				dismissTimeout: 5000,
			});
		}
		return '';
	};

	const isLoading = () => {
		return memberReviews.state === 'loading' ? (
			<div className={Styles.spinnerContainer} data-testid="loading-reviews">
				<Spinner />
			</div>
		) : (
			renderEmptyReviews()
		);
	};

	const renderReviews = () => {
		return reviews.map((review) => (
			<ReviewWrapper
				className="review-wrapper"
				review={review}
				vendor={review.profile}
				key={review.id}
			/>
		));
	};

	const renderEmptyReviews = () => {
		return reviews?.length ? renderReviews() : <EmptyState />;
	};

	return (
		<div className={Styles.reviewsWrapper}>
			<div className={Styles.headerWrapper}>
				<H4>Your Reviews</H4>
			</div>
			{isLoading()}
			<div>
				<Snackbar ref={snackbarRef} />
			</div>
		</div>
	);
};

export default ReviewsWrapper;
