import { Button } from '@xo-union/tk-component-buttons';
import VendorTypeahead from '@xo-union/tk-component-vendor-typeahead';
import { Body2, H2, H4 } from '@xo-union/tk-ui-typography';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import AnalyticsConstants from '../../../../../constants/analytics';
import { AnalyticsContext } from '../../../../contexts/AnalyticsContext/AnalyticsContext';
import {
	handleEmptyVendor,
	selectVendor,
	updateSearch,
} from '../../../../redux/typeahead';
import Styles from './styles.scss';

export class SearchBanner extends PureComponent {
	static propTypes = {
		handleEmptyVendor: PropTypes.func.isRequired,
		history: PropTypes.object.isRequired,
		selectVendor: PropTypes.func.isRequired,
		updateSearch: PropTypes.func.isRequired,
		subText: PropTypes.string.isRequired,
		validity: PropTypes.string.isRequired,
		vendor: PropTypes.object.isRequired,
	};

	static defaultProps = {
		vendor: {},
		validity: 'neutral',
		subText: '',
	};

	static contextTypes = {
		router: PropTypes.object,
	};

	handleOpenModal = () => {
		const targetUri = this.props.vendor?.siteUrls[0]?.relativeUri;
		const url = targetUri.replace('/marketplace', ''); // take /marketplace off of uri

		this.props.history.push(`/review-wedding-vendors${url}#review-modal`, {
			vendor: this.props.vendor,
			isFromTypeahead: true,
		});
	};

	handleNextOnClick = () => {
		if (this.props.vendor.id && this.props.vendor.name) {
			const vendorProps = {
				vendorId: this.props.vendor.id,
				vendorName: this.props.vendor.name,
			};

			this.context.track({
				event: AnalyticsConstants.VENDOR_REVIEW_INITIATED,
				properties: {
					sourcePage: 'Review Hub',
					...vendorProps,
				},
			});
		}

		this.props.vendor.id
			? this.handleOpenModal()
			: this.props.handleEmptyVendor();
	};

	render() {
		return (
			<div className={Styles.searchBanner}>
				<div className={Styles.searchForm}>
					<H2 className={Styles.header}>Review Your Wedding Vendors</H2>
					<H4 className={Styles.subHeader}>Find Your Vendor</H4>
					<div className={Styles.formFields}>
						<VendorTypeahead
							state={this.props.validity}
							subText={this.props.subText}
							label="Vendor Name"
							value={this.props.vendor.name}
							onInputChange={this.props.updateSearch}
							onChange={this.props.selectVendor}
						/>
						<Button
							size="md"
							color="primary"
							className={Styles.button}
							value={this.props.vendor.name}
							onClick={this.handleNextOnClick}
						>
							Next
						</Button>
					</div>
					<Body2 className={Styles.photoCredit}>
						<p>photo credit: scobey photography</p>
					</Body2>
				</div>
			</div>
		);
	}
}

SearchBanner.contextType = AnalyticsContext;

export function mapStateToProps(state) {
	return {
		name: state.typeahead.name,
		vendor: state.typeahead.vendor || {},
		validity: state.typeahead.validity,
		subText: state.typeahead.subText,
	};
}

export const mapDispatchToProps = {
	updateSearch,
	selectVendor,
	handleEmptyVendor,
};

const enhancedSearchBanner = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter,
);

export default enhancedSearchBanner(SearchBanner);
