import { IconButton } from '@xo-union/tk-component-icons';
import { Tooltip, TooltipContainer } from '@xo-union/tk-component-tooltips';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Review from '../Review';
import Styles from './styles.scss';

export default class ReviewWrapper extends Component {
	static propTypes = {
		review: PropTypes.object.isRequired,
		vendor: PropTypes.object,
	};

	static defaultProps = {
		vendor: null,
	};

	disputedReview(review) {
		if (
			!review?.isVendorDisputed ||
			review?.disputedReviewStatus !== 'not reviewed'
		) {
			return null;
		}

		return (
			<TooltipContainer className={Styles.tooltipContainer}>
				<span className={Styles.underReviewWrapper}>
					<IconButton className={Styles.info} name="info" size="sm" />
					<div className={Styles.underReview}> Under Review </div>
				</span>
				<Tooltip arrowPosition="left" className={Styles.underReviewTip}>
					Your review is currently being evaluated by The Knot. A team member
					may reach out for more information.
				</Tooltip>
			</TooltipContainer>
		);
	}

	render() {
		const { review, vendor } = this.props;

		if (!vendor) {
			return null;
		}

		const nameClasses = `${Styles.vendorName} vendorName`;

		return (
			<div className={Styles.cardWrapper}>
				<p className={nameClasses}>{vendor.name}</p>
				{this.disputedReview(review)}
				<Review review={review} vendor={vendor} />
			</div>
		);
	}
}
