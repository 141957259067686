export default {
	FETCH_REVIEWS: 'FETCH_REVIEWS',
	FETCH_REVIEWS_SUCCESS: 'FETCH_REVIEWS_SUCCESS',
	FETCH_REVIEWS_ERROR: 'FETCH_REVIEWS_ERROR',
	FETCH_VENDORS: 'FETCH_VENDORS',
	FETCH_VENDORS_SUCCESS: 'FETCH_VENDORS_SUCCESS',
	FETCH_VENDORS_ERROR: 'FETCH_VENDORS_ERROR',
	MORE_OR_LESS_CHAR_LIMIT: 244,
	REVIEWS_PER_PAGE: 25,
	UPDATE_SEARCH: 'UPDATE_SEARCH',
	SELECT_VENDOR: 'SELECT_VENDOR',
	CLEAR_VENDOR: 'CLEAR_VENDOR',
	FETCH_VENDOR: 'FETCH_VENDOR',
	FETCH_VENDOR_SUCCESS: 'FETCH_VENDOR_SUCCESS',
	FETCH_VENDOR_ERROR: 'FETCH_VENDOR_ERROR',
	HANDLE_EMPTY_VENDOR: 'HANDLE_EMPTY_VENDOR',
};
