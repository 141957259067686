import { useAppSelector } from '@redux/hooks';
import { useEffect } from 'react';

const buildUrl = (id = '') => {
	const env = process.env.NODE_ENV === 'production' ? 'prod' : 'qa';
	return `https://${env}-reviews-api.localsolutions.theknot.com/storefront/${id}/view-review-wedding-vendors`;
};

const trackReviewsPage = (
	membership: Redux.Membership,
	settings: Redux.Settings,
) => {
	const method = 'POST';
	const headers = { 'Content-Type': 'application/json' };

	if (membership?.member) {
		const { email, id } = membership.member;
		const url = buildUrl(id);

		return fetch(url, {
			method,
			headers,
			body: JSON.stringify({
				email,
				memberId: id,
				tkMember: true,
			}),
		});
	}
	const { anonymousId } = settings;
	const url = buildUrl(anonymousId);
	return fetch(url, {
		method,
		headers,
		body: JSON.stringify({
			memberId: anonymousId,
			tkMember: false,
		}),
	});
};

export const useTrackReviewPage = () => {
	const membership = useAppSelector((state) => state.membership);
	const settings = useAppSelector((state) => state.settings);
	useEffect(() => {
		if (membership.inProgress?.FETCH === false) {
			trackReviewsPage(membership, settings);
		}
	}, [membership.inProgress]);
};
